@font-face {
    font-family: 'Fredoka-SemiBold'; /* Name of your font */
    src: url('./fonts/fredoka/Fredoka-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Roboto-Regular';
    src: url('./fonts/roboto/Roboto-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: 400;
  }